<template>
  <div
      style="display: flex; flex-direction: column; align-items: center; justify-content: center; background-color: white; min-height: 100vh; text-align: center;">
    <h3 class="text-primary font-weight-bold text-nowrap">
      {{
        activation_type !== "trial"
            ? "Payment Successful"
            : "Activating 60 Day Trial"
      }}
    </h3>
    <p v-if="this.activation_type !== 'trial'">
      Your payment was successful! You can <br/>
      now continue using Figa.
    </p>
    <div style="margin-top: 20px;">
      <b-spinner small label="Small Spinner"></b-spinner>
      <p v-html="activation_type !== 'trial' ? 'Finalizing Your Account' : stage"></p>
    </div>

    <b-modal hide-footer v-model="showLogoutModel" style="text-align: center">
      <template #modal-title>
        Package Activation Completed!
      </template>
      <div class="d-block text-center">
        <h6 class="text-dark font-primary font-weight-bold mb-xl-4 title  d-xl-inline">
          Please sign in again to load all features on your package.
        </h6>
      </div>
      <div style="text-align: center;padding-left: 30%">
        <FormButton variant="main-green" isPill class="font-weight-normal text-secondary mt-3 mb-4 w-50" type="submit"
                    @click.native="logoutFn">
          <span style="color: white;font-weight: bold">Got it</span>
        </FormButton>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import FormButton from "@/components/Form/FormButton";
import {GetUserProfile} from "@/services/settings.service";
import axios from "axios";
import { trial_period } from "@/util/trialPeriod";

export default {
  name: "PaymentSuccess",
  data() {
    return {
      sessionId:null,
      activation_type: null,
      package_switch: null,
      stage: "Welcome to your 60 day free trial period.<br> Signup for additional weeks by entering your promo code before expiry.<br> Figabooks - simple and easy!",
      showLogoutModel: false,
      trialPeriodDays :null,
    };
  },
  components: {
    FormButton,
  },

  mounted() {
    this.trialPeriodDays = trial_period;
    this.sessionId = this.$route.query.session_id
    this.activation_type = localStorage.getItem("activation_type");
    if(this.sessionId){
      this.package_switch = true
    }else{
      this.package_switch = this.$store.getters['storeTempValues/getSwitchPackage']
    }
    this.completeRegistration();
  },
  methods: {
    ...mapActions({
      registerBusiness: "businessRegistration/registerBusiness",
      workspaceLogin: "auth/workspaceLogin",
      localLogIn: "auth/localLogIn",
      addPackage: "auth/addPackage",
      paymentStatus: "auth/paymentStatus",
      changePackage: "auth/changePackage",
    }),
    ...mapGetters({getBusinessId: "businessRegistration/getBusinessId"}),
    ...mapActions({logOut: "auth/logOut"}),
    async completeRegistration() {
      if (!this.package_switch) {
        try {
          await this.registerBusiness();
          await this.workspaceLogin(this.getBusinessId());
          const packageId=this.$store.getters['storeTempValues/getSetSelectedPackageName']
          const price_id=this.$store.getters['storeTempValues/getSetSelectedPackage']
          const email=this.$store.getters['storeTempValues/getTempEmail']
          const isAnnual=this.$store.getters['storeTempValues/getIsAnnual']
          const password=this.$store.getters['storeTempValues/getTempPassword']
          await this.addPackage({
            packageId,
            price_id,
            email,
            trial: true,
            isAnnual,
          });
          await this.localLogIn({
            email: email,
            password,
          });
          this.$store.dispatch("storeTempValues/clearState");
          this.$router.push("/");
        } catch (e) {
          this.$router.push("/login")
        }
      }
      let {data} = await GetUserProfile();
      console.log(data)
      let status;
      if (data.active_trial) {
        status = {
          data: {
            payment_status: 'paid',
            status: 'complete'
          }
        }
      } else {
        const tracking_id = this.$store.getters['storeTempValues/getTrackingId'] || this.sessionId
        status = await this.paymentStatus({
          tracking_id,
        });
      }
      if (
          status.data.payment_status === "paid" &&
          status.data.status === "complete"
      ) {
        if (this.package_switch) {
          if(this.sessionId){
            const res= await axios.get(`/packages//meta_data`,{ params: { session_id:this.sessionId } })
            await this.changePackage({
              packageId: res.data.data.selected_package_name,
              price_id: res.data.data.selected_package,
              tracking_id:this.sessionId,
              isAnnual: res.data.data.isAnnual,
            });
          }else{
            const packageId=this.$store.getters['storeTempValues/getSetSelectedPackageName']
            const price_id=this.$store.getters['storeTempValues/getSetSelectedPackage']
            const tracking_id=this.$store.getters['storeTempValues/getTrackingId']
            const isAnnual=this.$store.getters['storeTempValues/getIsAnnual']
            await this.changePackage({
            packageId,
            price_id,
            tracking_id,
            isAnnual,
          });
          }
          this.showLogoutModel = true;
          this.stage = ""
        }
      } else if (status.data.status === "open") {
        this.stage = 'Validating your transaction...'
        setInterval(() => {
          window.location.reload();
        }, 2000);
      } else if (

          status.data.status !== "open" &&
          status.data.status !== "complete" &&
          status.data.payment_status !== "paid"
      ) {
        this.stage = 'Finalizing Your Account',
            this.$router.push("/payment/error");
      }
    },
    logoutFn() {
      localStorage.clear();
      this.logOut();
      location.replace("/login");
    },
  },
};
</script>

<style scoped></style>
