<template>
  <b-row  class="d-flex flex-row align-items-center justify-content-center w-100 mr-3"
    no-gutters>
    <b-col col="12">
     <div class="d-flex flex-column align-items-center justify-content-center" style="min-height: 100vh;">
        <h1 class="text-danger">Payment Can't Complete.</h1>
        <b-button variant="main-green" @click="GoBack">Back To Figabooks</b-button>
     </div>
     
    </b-col>
  </b-row>
</template>

<script>
export default {
 name:'InvoicePaymentCancel',
 methods:{
    GoBack(){
        this.$router.push('/login')
    }
 }
}

</script>

<style>

</style>