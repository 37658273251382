<template>
  <b-row  class="d-flex flex-row align-items-center justify-content-center w-100 mr-3"
    no-gutters>
    <b-col col="12">
     <div class="d-flex flex-column align-items-center justify-content-center" style="min-height: 100vh;">
      <div class="logo-box text-center">
          <b-img
            src="@/assets/welcomePageIcon.png"
            class="logo-img ml-5 "
            alt="Finance"
          ></b-img>
        </div>   
        <h3 class="font-primary font-weight-bold">Figabooks</h3>
      <h1>Invoice Payment Successfully Done.</h1>
        <b-button variant="main-green" @click="GoBack">Back To Figabooks</b-button>
     </div>
     
    </b-col>
  </b-row>
</template>

<script>
export default {
 name:'InvoicePaymentSuccess',
 methods:{
    GoBack(){
        this.$router.push('/login')
    }
 }
}

</script>

<style scoped>
.logo-img{
  height: 200px;
  width: 200px;
  object-fit: cover;
}
</style>